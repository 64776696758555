.cookie-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 200;
  background: $white;
  box-shadow: 0 -2px 16px 0px rgba(0, 0, 0, .16);

  p {
    font-size: 15px;
    margin: 1em 0;
  }

  .button{
    margin: 1em 0;
    display: inline-block;
    // white-space: nowrap;
  }
}
