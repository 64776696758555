blockquote {
  background-color: $light-grey;
  color: $primary-color;

  border-radius: $global-radius $global-radius 0 0;

  font-size: 24px;
  position: relative;
  border-left: 0;
  position: relative;

  padding: 1em 1em 2em;

  @include breakpoint(large) {
      padding: 3em 5em;
  }

  &::before {
    content: "";
    display: block;
    background-image: url("../img/left-quote.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;

    width: 40px;
    height: 40px;
    margin-bottom: 1em;

    top: 2em;
    left: 2em;

    @include breakpoint(large) {
      top: 2em;
      left: 2em;
      position: absolute;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;

    width: 100%;
    height: 30px;

    bottom: 0;
    left: 0;
  }

  cite {
    font-style: normal;
    font-size: 18px;
    color: $primary-color;

    &::before {
      content: '';
      display: none;

    }
  }
}
