.hero {

  margin-top: 72px;
  position: relative;
  padding: 4em 0;

  background-color: $secondary-color;

  background-repeat: no-repeat;
  background-size: 500%;
  background-position: top left;

  background-image: var(--mobile-background);

  height: 100vh;
  max-height: none;


  @include breakpoint(medium) {
    max-height: 640px;
    margin-top: 130px !important;
    background-position: center center;
    background-image: var(--desktop-background);
  }


  &.scroll-reveal-animation {
    opacity: 0;
    transition: opacity .3s ease-out, background-size .3s ease-out;

    .hero__h1{   
      &::after {
        max-width: 0;
        transition: max-width .3s ease-out .5s;
      }
    }

    &.active {
      opacity: 1;
      background-size: cover;

      .hero__h1{   
        &::after {
          max-width: 210px;
        }
      }
    }
  }

  .grid-container{
    height: 100%;

    .grid-x {
      height: 100%;
      align-items: self-end;

      @include breakpoint(large) {
        align-items: center;
      }
      &--top {
        align-items: self-start;
        
        @include breakpoint(large) {
          align-items: self-start;
        }
      }
    }


  }

  &--inline {
    margin-top: 0 !important;
    max-height: 800px;
  }
  &--contact {
    height: auto;
    max-height: none;

    @include breakpoint(large) {
      background-color: $secondary-color;
      background-position: 50% 25%;
      background-size: 24%;
      // background-image: url("../img/background-contact.svg");
    }
  }

  &--plain {
    height: auto;
    max-height: none;

    background-color: $secondary-color;
  }

  &--about {
    background-color: $secondary-color;
    // background-position: left bottom;
    // background-size: 20%;
    // background-image: url("../img/background-about.svg");

    @include breakpoint(large) {

    }
  }


}

.hero__content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;

  padding: 1em !important;

  background-image: linear-gradient(0deg, rgba($secondary-color,.8) 80%, rgba($secondary-color,0) 100%);

  @include breakpoint(medium) {
    background-image: none;
    // padding: 3em !important;
  }

  // Fix for iOS14
  & > * {
    @supports (-webkit-touch-callout: none) and (not(translate: none)) {
      margin: 0 0 1em 0 !important;
    }
  }


  &--simple {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;

    background: transparent;
      &::after {
        display: none;
      }
  }



}

.hero__h1{
  margin: 0;
  font-size: 28px;
  color: white;
  font-weight: 300;
  line-height: 1.2em;

  @include breakpoint(large) {
    font-size: 50px;
  }

  small {
    color: white;
    font-size: 44px;
    font-weight: 300;

    @include breakpoint(large) {
      font-size: 45px;
    }
  }
  img {
    margin-left: 4px;
  }

  &::after {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    max-width: 210px;
    background-color: $primary-color;
    border-radius: 4px;
    margin-top: .5em;
  }
}

.hero__h1--big{
  margin: 0;
  font-size: 28px;
  line-height: 1.2em;

  @include breakpoint(large) {
      font-size: 70px;
  }
}

.hero__h2 {
  margin: 0;
  font-weight: normal;
  line-height: 1.4em;
  font-size: 22px;

  @include breakpoint(large) {
      font-size: 30px;
      max-width: 22ch;
  }

}

.hero__description{
  margin: 0;
  font-size: 18px;
  max-width: 36ch;
  color: white;
  font-weight: 300;

  &--centered {
    max-width: 72ch;
    margin: 0 auto;
  }

}

.hero__description--big{
  margin: 0;
  font-weight: normal;
  line-height: 1.4em;
  font-size: 22px;

  @include breakpoint(large) {
      font-size: 30px;
  }
}

.hero__icon {
  max-width: 70px;
  height: auto;
}

.hero__button {
  width: fit-content;
  margin-bottom: 0;
}

.hero__logo {
  max-width: 160px;
}
