.button {

  overflow: hidden;
  position: relative;
  z-index: 1;
  font-variation-settings: "wght" 400;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 110%;
    height: 100%;
    border-radius: $global-radius;
    transition: left .3s ease-in-out;
    left: -110%;
    top: 0;
    z-index: -1;
    background: darken($primary-color, 5);
  }

  border-color: transparent;

  &:hover {
    &::before {
      left: -10%;
    }
  }

  &.primary {
    &::before {
      background: darken($primary-color, 5);
    }
  }


  &.secondary {
    color: $white;
    
    &::before {
      background: lighten($secondary-color, 10);
    }

    &:hover {
      color: $white;
    }
  }

  &.white {
    background-color: $white;
    color: $primary-color;

    &:hover{
      color: $white;

      &::before {
        background: $primary-color;
      }

    }
  }

  &.ghost {
    background-color: $white;
    color: $primary-color;
    border: 2px solid $primary-color;

    &:hover{
      color: $white;

      &::before {
        background: $primary-color;
      }

    }
  }



}


