.logo-wall{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3em 0;

  &__logo-container{

    margin: 1em;
    align-self: flex-start;
    place-self: center;
    text-align: center;

    width: calc(33% - 3em);

    @include breakpoint(medium) {
      width: calc(25% - 3em);
    }
    @include breakpoint(large) {
      width: calc(20% - 3em);
    }
  }

  &__logo {

    max-height: inherit;
  }


}
