.section--accordion {
  background-color: $light-gray;

  color: $white;

  @include breakpoint(large) {
    background-position: -10% center, 0 center;
    background-size: contain;
  }

  h2 {
    // color: $off-white;
    margin-bottom: 1em;
  }
}

.accordion {
  &--reverse {
    background-color: transparent;
    // color: ;

    .accordion-item {

      &:first-child > :first-child, &:last-child:not(.is-active) > .accordion-title, &:last-child > :last-child {
        border-radius: 0 !important;
        border-bottom: none;

      }

      &.is-active {
        .accordion-title {
          color: $primary-color;
        }
      }
    }

    .accordion-title{
      color: $secondary-color;
      font-size: 18px;
      font-weight: 600;
      border: none;
      border-top: 1px solid $primary-color;
      padding-right: 2em;
      transition: color .3s ease-out;

      &:hover, &:focus {
        background-color: transparent;
      }
    }

    .accordion-content {
      background-color: transparent;
      // color: $white;
      border: none;

    }
  }
}
