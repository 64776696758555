section.scroll-reveal-animation {

  .grid-container {
    opacity: 0;
    margin-top: 100px;
    transition: margin-top .6s cubic-bezier(0.65, 0, 0.35, 1), opacity .6s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active {
    .grid-container{
      opacity: 1;
      margin-top: 0;
    }
  }

}

.section {
    padding: 4em 0;

    @include breakpoint(large) {
      padding: 6em 0;
    }

    &.scroll-reveal-animation {
      opacity: 0;
      transition: opacity .3s ease-out;

      &::before {
        opacity: 0;
        transition: opacity 1s ease-out .6s;
      }

      &.active {
        opacity: 1;

        &::before {
          opacity: 1;
        }

      }
    }

    &--light-grey {
      background-color: $light-grey;
    }
}


.section--collapse+.section--collapse {
   padding-top: 0 !important;
}


.grid-container {

  &--grey {
    background-color: $light-grey;
    padding: 2em;
    @include breakpoint(large) {
      padding: 6em;
    }
  }

  &--green {
    background-color: $primary-color;
    background-image: url('../img/feature-navillus-pointing-in.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 144%;
    padding: 3em;

    @include breakpoint(medium) {
      padding: 7em;
      background-size: 90%;
    }

    p, h1, h2, h3, h4, h5, li, a {
      color: white;
    }

    .button {
      margin-bottom: 0;
    }
  }
}

