$cyan: #7AC3E0;
$magenta: #E01F5A;
$yellow: #ECB22D;
$green: #2FB67C;

$off-white: #DCD3C8;

$red: $magenta;
$blue: $cyan;

$gray: #dadada;
$thirdly-color: $yellow;

$light-gray: #F8F8F8;
$light-grey: #F8F8F8;
$dark-gray: #999999;
$dark-grey: $dark-gray;

$white: #ffffff;

$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #3C3C3B;
$white: #fefefe;

$dark: #06121E;
