.section--cards {
  overflow-x: hidden;
  overflow-x: clip;

  &.scroll-reveal-animation {

    .card {
      opacity: 0;
      transition: opacity 1s ease-out;

      .card__icon{
        opacity: 0;
        clip-path: circle(0);
        transition: clip-path 1s ease-out, opacity 1s ease-out;
      }

    }

    @for $i from 1 through 10 {
       .cell:nth-of-type(#{$i}) {
         .card {
          transition-delay: calc(#{$i} * 150ms );

          .card__icon {
            transition-delay: calc(#{$i} * 150ms );
          }
         }
       }
    }

    &.active {
      .card {
        opacity: 1;
        .card__icon{
          opacity: 1;
          clip-path: circle(50%);
        }
      }
    }
  }

}

.card-grid{
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  @include breakpoint(large) {
    gap: 3rem;
  }
}

.card {

  display: flex;
  flex-direction: column;

  background: $white;

  scroll-snap-align: start;
  transition: all 0.2s;

  max-width: 320px;

  position: relative;
  gap: 1em;

  @include breakpoint(large) {
    flex: 0 0 100%;
    width: calc( 100% / 3 - 3em);
  }

  &--post {
    padding: 0 1em;

    .card__section {
      padding: 1em 0 !important;
    }

  }

  &--post-full {

    margin: 0;

    flex-grow: 1;
    flex-basis: 0;

    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;

    padding: 1.4em;

    @include breakpoint(large) {
      flex: 0 0 50%;
      width: calc(50% - 1.5rem);
      max-width: calc(50% - 1.5rem);
    }

    .card__section {
      padding: 1em 0 !important;
      text-align: left;

    }

    .card__synopsis {
      font-size: 16px;
      text-align: left;

    }

    .card__image {
      max-width: 300px;
      max-height: 190px;
      align-self: center;
    }

    .button {
      align-self: center;
    }

  }

  &--fluid {
    flex-grow: 1;
    flex-basis: 0;

    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    @include breakpoint(large) {
      flex: auto;
      width: auto;
      max-width: 380px;
      padding: 0;
    }

    .card__title, .card__synopsis {
      text-align: left;
    }
    .card__image{
      max-width: 80px;
      align-self: flex-start;
    }
  }

  

  &--radius {
    border-radius: $global-radius;
    box-shadow: 0 6px 9px -1px rgba(0,0,0,0.2);
  }








  &__link {
    text-decoration: none;
    color: inherit;
    opacity: .80;
    transition: opacity .3s ease-out;

    &:hover{
      opacity: 1;
      text-decoration: none;
      color: inherit;
    }
  }

  &__section {
    flex-grow: 1;
    padding: 1.5em;

    @include breakpoint(large) {
      padding: 2em 3em;
    }
  }

  &__section--dark {
    background-color: $dark;
    margin: 1em;
    padding: 2em;
    border-radius: 1em 0 0 1em;

    border-radius: 1em 1em 0 0;
    flex-grow: 0;
    min-height: 15em;

    @include breakpoint(large) {
      border-radius: 1em 0 0 1em;
    }
  }

  &__section--grey {
    background-color: $light-gray;
    margin: 1em;
    padding: 2em;
    border-radius: 1em 1em 0 0;
    flex-grow: 0;
    min-height: 15em;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__footer-section {
    padding: 1.5em;
    @include breakpoint(large) {
      padding: 2em 3em;
    }
  }

  &__read-time {
    &::before{
      content: '\2022';
      margin-right: .5em;
      color: $black;
    }
  }

  &__image {
    max-width: 120px;
    max-height: 100px;
    align-self: center;
  }
  &__icon {
    max-width: 120px;
    align-self: flex-start;
  }
  

  &__image--radius {
    overflow: hidden;

    @include breakpoint(small) {
      margin: .5em !important;
      border-radius: 0 0 ($global-radius / 1.4) ($global-radius / 1.4 );
    }

    @include breakpoint(large) {
      margin: 1.5em !important;
      border-radius: 0 0 ($global-radius / 2) ($global-radius / 2);
    }

  }


  &__image--43 {
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  &__title {
    font-weight: normal;
    margin-bottom: .8em;
    font-size: 1.2em;
    color: $secondary-color;
    text-align: center;

    @include breakpoint(large) {
      font-size: 1.5em;
    }
  }

  &__title--white {
    color: $white;
  }


  &__surtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1em;
    margin-top: -1em;
  }
  &__subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: .5em;
  }
  &__synopsis {
    font-size: 16px;
    // margin-bottom: 1em;
    text-align: center;
  }
  &__synopsis--white {
    color: $white;
  }
  &__author {
    font-size: 16px;
    font-weight: bold;
  }
  &__date {
    font-size: 16px;
    color: $green;
  }
  &__logo {
    background: $white;
    border-radius: 50%;
    position: absolute;
    width: 130px;
    height: 130px;
    top: -110px;
    box-shadow: 0 6px 9px -1px rgba(0,0,0,0.1);
    padding: 1em;
    overflow: hidden;
    display: flex;
  }
  // &__logo-image {

  // }


  &__content {
    padding: 2em;
  }

  ul {
    list-style: none;
    margin: 0;
    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: .5em;

      b {
        display: block;
      }

    }
  }

}


.card a, a.card {
  color: inherit;

  .card__arrow {
    transition: transform .3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:hover {
    color: inherit;

    .card__arrow {
      transform: translateX(2em);
    }
  }
}

.card a:not(.button) {
  display: contents;
}

.card-container {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;

  .card {
    @supports (-webkit-touch-callout: none) and (not(translate: none)) {
      margin: 1em;
    }
  }
}

.card-scroller {

  position: relative;

  .card {
    flex: 0 0 80%;
    width: 80%;

  }

  &__stage {
    display: flex;
    padding: 2em 1em 0 ;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 1em;
    width: calc(100vw - (100vw - 100%));
    gap: 2em;
    // margin: 0 0 2em 0;

    .card {
      @supports (-webkit-touch-callout: none) and (not(translate: none)) {
        margin: 1em;
      }
    }

    &::-webkit-scrollbar {
      height: 12px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 92px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color;
    }

    &::-webkit-scrollbar-track {
      background: $light-gray;
    }

    &--locked {
      justify-content: center;

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }


  }


  &__handles {
    display: none;

    @include breakpoint(large) {
      position: absolute;
      width: 100vw;
      width: -webkit-fill-available;
      top: 0;
      height: 100%;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: none;
      opacity: 1;
      transition: opacity .3s ease-out;
    }

    &--locked {
      opacity: 0;
    }

  }

  &__prev, &__next{
    pointer-events: all;
    height: 6em;
    width: 3em;

    transition: .3s all ease-out;


  }

  &__label {
    display: none;
  }

  &__prev {
    border-radius: 0 160px 160px 0;
    padding-left: 0.6em;
    transform: translateX(-.5em);

    &:hover {
      transform: translateX(0);
    }

  }
  &__next {
    border-radius:  160px 0 0 160px;
    padding-right: 0.6em;
    transform: translateX(.5em);

    &:hover {
      transform: translateX(0);
    }

  }

}
