.pb4 {
  padding-bottom: 4em;
}
.grid-container--small {
  max-width: 48rem !important;
}

.grid-container--medium {
  max-width: 64rem !important;
}

.grid-container--large{
  max-width: 86rem !important;
}

.grid-container--full {
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
}


.grid-x>.medium-160 {
  @include breakpoint(large) {
    width: 260px;
  }
  @include breakpoint(xlarge) {
    width: 306px;
  }
}

.cell {
  z-index: 1;
}

.cell--rich-text {
  p {
    max-width: 55ch;
  }
  a {
    word-wrap: break-word;
  }
  ul {
    list-style: none;
    margin: 2em 0 2em 0;
    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: .8em;
      max-width: 55ch;

      &::before {
        content: url('../img/green-arrow.svg');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }

      b {
        display: block;
      }

    }
  }
  // trying to remove any margin form the end of a last element
  & >:last-child {
    margin-bottom: 0;
  }
}
