.footer {

    background: $secondary-color;
    color: $white;

    padding: 4em 0;

    &__title {
      font-size: 22px;
      font-weight: bold;
    }

    &__logo {
      margin-bottom: 2em;
    }

}

.footer__section{

  border-top: 1px solid rgba($white, 0.2);
  padding-top: 2em;
  margin-top: 2em;

}

.menu--footer {

  display: flex;
  justify-content: flex-start;

  padding: 0;
  margin: 0;

  a {
    padding: .5em 0;
    font-size: .8em;
    color: $white;
    line-height: 1.4em;

    @include breakpoint(medium) {
      margin: 0 2.5em 0 0;
    }

    &:hover {
      color: $white;
    }
  }

  li {
    padding: 0;
    margin: 0;

    &.quarter {
      width: 100%;
      @include breakpoint(medium) {
        width: 20%;
      }
    }

    &.third {
      width: 100%;
      margin: .5em 0;

      @include breakpoint(medium) {
        margin: 0;
        width: 33.33%;
      }
    }

    &.half {
      width: 100%;
      @include breakpoint(medium) {
        width: 40%;
      }
    }
  }
}


.menu--footer-end {
  justify-content: space-around;
  gap: 1em;

  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}



.copyright {
  font-size: 12px;
}


.menu--footer-sub{

    display: flex;
    flex-direction: column;

    li {
      a {
        color: $white;
        padding: 0.4rem 0;
        font-size: .8em;
      }
      &.active a {
        background: none;
        color: $white;
      }
    }

}

.footer__spread-parts{
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 1em;


  // Fix for iOS14
  & > * {
    @supports (-webkit-touch-callout: none) and (not(translate: none)) {
      margin: 0 0 1em 0 !important;
    }
  }

  @include breakpoint(medium) {
    align-items: center;
  }

  .button {
    align-self: flex-start;
    margin: 1em 0;

    @include breakpoint(medium) {
      align-self: flex-end;
      margin: 0 0 1em 0;
    }
  }
}

.social-links{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;


  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}
