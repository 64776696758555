.tabs-content {
  border-radius: 0;

  margin-top: 2em;

  @include breakpoint(large) {
    margin-top: 0;
  }
}

.tabs-section-title {
    margin-bottom: 1.2em;
    margin-left: 24px;
}


.tabs-title {

  a {
      border-radius: $global-radius;
      position: relative;
      transition: all .3s ease-out;
      margin-bottom: .5em;
      overflow: hidden;
      font-variation-settings: "wght" 400;

      &::after {
        content: "";
        display: block;
        position: absolute;
        background-image: url("../img/chevron-right.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;

        height: 100%;
        width: 30px;

        bottom: 0;
        right: 1em;

        // https://isotropic.co/tool/hex-color-to-css-filter/
        filter: invert(100%) sepia(42%) saturate(0%) hue-rotate(281deg) brightness(101%) contrast(101%);

        transition: filter .3s ease-out;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 110%;
        height: 100%;
        border-radius: $global-radius;
        background: $primary-color;
        transition: left .6s ease-in-out;
        left: -110%;
        top: 0;
        z-index: -1;
      }

      &:focus, &[aria-selected='true'] {

        background: transparent;
        color: $white;

        &:hover{
          background: transparent;
        }

        &::after {
          filter: none;
        }
        &::before {
          left: -10%;
        }
      }

      &:hover{

        background: lighten($primary-color, 75);

        &::after {
          // filter: none;
        }
      }
  }
}

.tabs-panel {
  position: relative;

  .button {
    position: absolute;
    right: 32px;
    bottom: 0;
    margin-bottom: 0;
    transform: translateY(50%);
  }

  h3 {
    color: $primary-color;
    font-size: 21px;
    font-weight: 400;
  }
  animation-name: tabsIn;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

}

@keyframes tabsIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);  
    opacity: 1;
  }
}