body {
  font-family: "rotunda-variable", sans-serif;
  font-variation-settings: "wght" 300;
}

h2 {
  max-width: 52ch;
}
p {
  font-size: 16px;
  margin: 1em 0;

  @include breakpoint(large) {
    font-size: 18px;
  }
}
.large{
  font-size: 24px;
  // max-width: 52ch;

  @include breakpoint(large) {
    font-size: 32px;
  }

}
